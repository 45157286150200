import React, {useState, useEffect, useMemo, useCallback, useRef} from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import {Link, navigate} from 'gatsby'
import {useForm} from 'react-hook-form'
import qs from 'qs'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import CatalogueIcon from '../../components/CatalogueIcon'
import useKeyPress from '../../hooks/useKeyPress'
import RichInput from '../../components/RichInput'
import media from '../../styles/media'
import {bodyFontFamily} from '../../styles/typography'
import FileUpload from '../../components/FileUpload'
import Alert from '../../components/Alert'
import Snackbar from '@mui/material/Snackbar'
import YearPicker from "./YearPicker"
import TaggedAutoComplete from "./TaggedAutoComplete"
import SimpleButton from "./SimpleButton"
import Autocomplete from "./Autocomplete"
import TextField from "./TextField"
import EditError from "./EditError"
import Checkbox from "./Checkbox"

const Wrap = styled.div`
  background: var(--color-backgroundSecondary);
  padding: 10px 20px;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 30%);
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  //padding-top: 8px;
  //padding-right: 5px;
  //padding-left: 0;
  align-items: flex-start;
  h1 {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 30px;
    font-family: ${bodyFontFamily.join(', ')};
    font-weight: 400;
  }
  // Sticky
  position: sticky;
  padding: 18px 20px 10px; 
  margin: -8px -20px -3px;
  inset-block-start: 0;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}
`

const Form = styled.form`
  margin: 0;
  h4 {
    margin-top: 20px;
    font-size: 24px;
    font-family: ${bodyFontFamily.join(', ')};
    font-weight: 400;
  }
`

const FormGroup = styled.div`
  margin: 10px 0;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const CatOptionItem = styled.li`
  display: flex;
  span {
    margin-left: 10px;
  }
`

const FormDivideWrap = styled.div`
  display: flex;
  @media screen and ${media.ltTablet} {
    flex-direction: column;
  }
`

const FormMain = styled.main`
  flex: 3;
  min-width: 0;
  @media screen and ${media.ltLaptop} {
    flex: 1;
  }
`

const FormSide = styled.aside`
  flex: 2;
  padding-left: 40px;
  min-width: 0;
  @media screen and ${media.ltLaptop} {
    flex: 1;
  }
  @media screen and ${media.ltTablet} {
    padding-left: 0;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
  // Sticky
  &.editing {
    position: sticky;
    padding: 8px 20px; 
    margin: -8px -20px;
    margin-top: 10px;
    inset-block-end: 0;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 1;
  }
`

const DateWrap = styled.div`
  display: flex;
  div:first-child {
    margin-right: 15px;
  }
`

const MIN_YEAR = new Date('1001-01-01')
const MAX_YEAR = new Date(`${new Date().getFullYear() + 1}-01-01`)

const DEFAULT_VALUES = {
  title: '',
  year: null,
  yearCirca: false,
  source: '',
  tags: [],
  people: [],
  links: [],
  category: null,
  format: null,
  status: null,
  subject: '',
  place: '',
  notes: '',
  images: [],
  documents: [],
}

const helperTexts = {
  title: {
    default: 'Mandatory use a descriptive title but avoid duplicating information in other fields',
    required: 'A title is required. Use a descriptive title but avoid duplicating information in other fields',
    unique: 'An entry with this title already exists. Use a descriptive title but avoid duplicating information in other fields',
  },
  category: {
    default: 'Mandatory category',
    required: 'A category is required',
  },
  year: {
    default: 'Optional year supplied if known',
    validate: `Year is invalid it must be between ${MIN_YEAR.getFullYear()} - ${MAX_YEAR.getFullYear()}. Optional supplied if known`,
  },
  links: {
    default: 'Optional links to other websites',
    validate: 'All links must be in a valid format e.g. https://website.com/path. Optional links to other websites',
  }
}

const helperText = ({ type, errors }) => {
  const errorType = errors[type] && errors[type].type
  const errorText = errorType && helperTexts[type] && helperTexts[type][errorType]
  return errorText || helperTexts[type] && helperTexts[type].default
}

const isValidUrl = (l) => {
  try {
    if (l === '') return true // Ignore empty
    const url = new URL(l)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch (_e) { }
  return false
}

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d)
}

const formData = ({ data, jwt }) => {
  const {
    title, year, source, people, tags, links,
    category, subject, format, place, status,
    notes, entryId, catalogueNo, deleteImages,
    deleteDocuments, yearCirca,
  } = data

  const formData = new FormData()
  formData.append('data', JSON.stringify({
    ...(catalogueNo && { catalogueNo }),
    title,
    year: year && `${year.getFullYear()}`,
    yearCirca,
    source,
    people: people.join('\n'),
    tags: tags.join('\n'),
    links: links.join('\n'),
    category: category?.id || null,
    format: format?.id || null,
    status: status?.id || null,
    subject,
    place,
    notes,
    jwtToken: jwt,
    deleteImages,
    deleteDocuments,
  }))

  data.images.forEach((f) => {
    formData.append(`files.images`, f, f.name)
  })

  data.documents.forEach((f) => {
    formData.append(`files.documents`, f, f.name)
  })

  return formData
}

const uniqOptions = ({ entries, field }) => {
  if (!entries) return []
  return Object.keys(entries.reduce((values, entry) => {
    const val = entry[field]
    if (typeof val !== 'string') return values
    val.split('\n').forEach(v => values[v] = 1)
    return values
  }, {})).filter(val => val && val.trim() !== '').sort()
}

const CatalogueForm = ({
  entry,
  entryLoading = false,
  entryError,
  editing = false,
  version,
  meta,
  entries,
  prevLocation,
  onCreated = () => {},
}) => {
  const [loadingMeta, setLoadingMeta] = useState(false)
  const [errorMeta, setErrorMeta] = useState(false)
  const [options, setOptions] = useState({
    categories: [],
    formats: [],
    statuses: [],
  })

  useEffect(() => {
    setLoadingMeta(true)
    setErrorMeta(false)
    meta()
      .then(m => setOptions(m))
      .catch(e => setErrorMeta(true))
      .finally(() => setLoadingMeta(false))
  }, [version])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: DEFAULT_VALUES })

  const [defaultValues, setDefaultValues] = useState(DEFAULT_VALUES)
  const [key, setKey] = useState(1)

  useEffect(() => {
    if (!editing || !entry || !options || options.categories.length === 0) return
    const updatedDefaultValues = {
      title: entry.title || '',
      year: entry.year && new Date(parseInt(entry.year), 0, 1),
      yearCirca: !!entry.yearCirca,
      source: entry.source || '',
      tags: entry.tags ? entry.tags.split('\n') : [],
      people: entry.people ? entry.people.split('\n') : [],
      links: entry.links ? entry.links.split('\n') : [],
      category: entry.category && options.categories.find(o => o.id === entry.category?.id),
      format: entry.format && options.formats.find(o => o.id === entry.format?.id),
      status: entry.status && options.statuses.find(o => o.id === entry.status?.id),
      subject: entry.subject || '',
      place: entry.place || '',
      notes: entry.notes || '',
      images: [],
      documents: [],
      entryId: entry.id,
      catalogueNo: entry.catalogueNo,
      uploadedImages: entry.images,
      uploadedDocuments: entry.documents,
      deleteImages: [],
      deleteDocuments: [],
    }
    // console.log('entry', entry)
    reset(updatedDefaultValues)
    setDefaultValues(updatedDefaultValues)
  }, [editing, entry, reset, options])

  const [alert, setAlert] = useState({ show: false })
  const [saving, setSaving] = useState(false)

  const loading = useMemo(() => {
    return (entryLoading || saving || loadingMeta)
  }, [entryLoading, saving, loadingMeta])

  const loadingError = useMemo(() => {
    return entryError || errorMeta
  }, [entryError, errorMeta])

  const entryNotFound = useMemo(() => {
    if (!editing) return false
    if (!entry && !loading && !loadingError) return true
  }, [editing, entry, loading, loadingError])

  const onSubmit = useCallback((data, e) => {
    e.preventDefault()
    setSaving(true)

    const { entryId } = data
    const jwt = localStorage.getItem('jwtToken').replace(/"/g, '')
    const query = qs.stringify({ populate: ['images', 'documents'] }, { encodeValuesOnly: true })
    fetch(`${process.env.GATSBY_STRAPI_API_URL}/api/catalogue-entries${entryId ? `/${entryId}` : ''}?${query}`, {
      method: entryId ? 'put' : 'post',
      headers: new Headers({
        'Authorization': `Bearer ${jwt}`,
      }),
      body: formData({ data, jwt }),
    })
      .then(rsp => rsp.json())
      .then((rsp) => {
        // console.log(rsp)
        if (rsp.error) {
          // Handle main error
          const err = _.get(rsp.error, 'details.errors[0]', {})
          if (err.name === 'ValidationError' && err.message === 'This attribute must be unique') {
            setError('title', { type: 'unique' }, { shouldFocus: true })
            return
          }
          // Handle other errors
          setAlert({
            show: true,
            title: `An error occurred whilst ${entryId ? 'saving' : 'creating'}`,
            message: rsp.error.message,
          })
        }
        // Updated
        if (!rsp.error && entryId) {
          // Clear uploaded images and documents
          setValue('images', [])
          setValue('documents', [])
          // Update images & docs
          setDefaultValues((curr) => ({
            ...curr,
            uploadedImages: (rsp.data.attributes.images.data || []).map(({ id, attributes }) => ({ id, ...attributes })),
            uploadedDocuments: (rsp.data.attributes.documents.data || []).map(({ id, attributes }) => ({ id, ...attributes })),
          }))

          setAlert({
            show: true,
            title: `Successfully saved entry ${data.catalogueNo}`,
            severity: 'success',
          })
        }
        // Created
        if (!rsp.error && !entryId) {
          onCreated({ e, entryId: rsp.data.attributes.catalogueNo })
        }
      })
      .catch((e) => {
        setAlert({
          show: true,
          title: `An unknown issue occurred whilst ${entryId ? 'saving' : 'creating'}`,
          message: 'Please check your connection and try again, If this issue persists contact an admin',
        })
        // console.log(e)
      })
      .finally(() => {
        setSaving(false)
      })
  }, [])

  const escapedPressed = useKeyPress('Escape')
  useEffect(() => {
    // if (escapedPressed) onClose()
  }, [escapedPressed])

  // Register custom form elements
  const regRef = useRef({})
  useEffect(() => {
    regRef.current.title = register('title', { required: true })
    regRef.current.year = register('year', {
      validate: (year) => (
        year == null ||
        (isValidDate(year)
          && year.getFullYear() >= MIN_YEAR.getFullYear()
          && year.getFullYear() <= MAX_YEAR.getFullYear())
      ),
    })
    register('yearCirca')
    register('source')
    register('tags')
    register('people')
    regRef.current.links = register('links', {
      validate: (links) => !links.some((l) => !isValidUrl(l)),
    })
    regRef.current.category = register('category', { required: true })
    register('subject')
    register('format')
    register('place')
    register('status')
    register('notes')
    register('images')
    register('documents')
    register('deleteImages')
    register('deletedDocuments')
    register('entryId')
    register('catalogueNo')
  }, [register])

  // OnChange
  const onNotesChange = useCallback((value) => { setValue('notes', value) }, [setValue])

  // Meta Data
  const [metaData, setMetaData] = useState({})
  useEffect(() => {
    (async () => {
      try {
        if (!entries) return
        setMetaData({
          sources: uniqOptions({ entries, field: 'source' }),
          people: uniqOptions({ entries, field: 'people' }),
          tags: uniqOptions({ entries, field: 'tags' }),
          subjects: uniqOptions({ entries, field: 'subject' }),
          places: uniqOptions({ entries, field: 'place' }),
        })
      } catch (e) {
        // console.log(e)
      }
    })()
  }, [entries])

  const onReset = useCallback((e) => {
    e.preventDefault()
    reset(defaultValues)
    setKey(k => k+1)
  }, [reset, defaultValues])

  if (loadingError || entryNotFound) {
    const type = loadingError ? 'error' : 'not-found'
    return (
      <EditError type={type} />
    )
  }

  // TODO Temp loading
  // if (entryLoading || loadingMeta) return null

  return (
    <>
      <Snackbar
        open={alert.show}
        autoHideDuration={10000}
        onClose={(e, reason) => {
          if (reason !== 'clickaway') setAlert((a) => ({ ...a, show: false }))
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert
            severity={alert.severity || 'error'}
            title={alert?.title}
            message={alert?.message}
            onClose={() => { setAlert((a) => ({ ...a, show: false })) } }
          />
        </div>
      </Snackbar>
      <Wrap>
        <Header>
          <h1>{editing ? `Editing ${entry?.catalogueNo || ''}` : 'Add catalogue entry'} </h1>
          <Link
            to={entry ? `/catalogue/${entry.catalogueNo}` : '/catalogue'}
            onClick={(e) => {if (prevLocation) { e.preventDefault(); navigate(-1)} }}
          >
            <IconButton
              aria-label="cancel add entry"
              // onClick={onClose}
              // size="medium"
              style={{position: 'relative', top: -8, right: -12}}
            >
              <CloseIcon/>
            </IconButton>
          </Link>
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormDivideWrap>
            <FormMain>
              <FormGroup>
                <TextField
                  id="title"
                  label="Title *"
                  type="text"
                  defaultValue={defaultValues.title}
                  helperText={helperText({ type: 'title', errors })}
                  variant="standard"
                  error={!!errors.title}
                  style={{width: '100%'}}
                  disabled={loading}
                  key={`title-${key}`}
                  onChange={(value) => { setValue('title', value) }}
                  _ref={e => regRef.current.title && regRef.current.title.ref(e)}
                />
              </FormGroup>

              <DateWrap>
                <FormGroup>
                  <YearPicker
                    defaultValue={defaultValues.year}
                    onChange={(value) => { setValue('year', value) }}
                    helperText={helperText({ type: 'year', errors })}
                    disabled={loading}
                    minYear={MIN_YEAR}
                    maxYear={MAX_YEAR}
                    key={`year-${key}`}
                  />
                </FormGroup>

                <FormGroup>
                  <Checkbox
                    label="Circa Year"
                    defaultValue={defaultValues.yearCirca}
                    helperText="The year is an approximation c.1980"
                    onChange={(value) => { setValue('yearCirca', value) }}
                    disabled={loading}
                    key={`yearCirca-${key}`}
                  />
                </FormGroup>
              </DateWrap>

              <FormGroup>
                <Autocomplete
                  freeSolo
                  options={metaData.sources ? metaData.sources.map((option) => option) : []} // TODO
                  disabled={loading}
                  label="Source"
                  margin="normal"
                  helperText="Optional source who supplied the item"
                  error={!!errors.source}
                  onChange={(event, newValue) => { setValue('source', newValue) }}
                  defaultValue={defaultValues.source}
                  key={`source-${key}`}
                />
              </FormGroup>

              <FormGroup>
                <TaggedAutoComplete
                  label="People"
                  // placeholder="Use , as a delimiter"
                  helperText="Optional list of people. For photographs start at the back row working from left to right"
                  options={metaData.people ? metaData.people.map((option) => option) : []}
                  defaultValue={defaultValues.people}
                  onChange={(value) => { setValue('people', value) }}
                  disabled={loading}
                  key={`people-${key}`}
                />
              </FormGroup>

              <FormGroup>
                <TaggedAutoComplete
                  label="Tags"
                  // placeholder=""
                  helperText="Optional add references to other catalogue numbers or points of significance to return search results e.g. snow"
                  options={metaData.tags ? metaData.tags.map((option) => option) : []}
                  defaultValue={defaultValues.tags}
                  onChange={(value) => { setValue('tags', value) }}
                  disabled={loading}
                  key={`tags-${key}`}
                />
              </FormGroup>

              <FormGroup>
                <TaggedAutoComplete
                  label="Links"
                  // placeholder=""
                  helperText={helperText({ type: 'links', errors })}
                  fullWidth
                  options={[]}
                  defaultValue={defaultValues.links}
                  onChange={(value) => { setValue('links', value) }}
                  error={!!errors.links}
                  _ref={e => regRef.current.links && regRef.current.links.ref(e)}
                  disabled={loading}
                  key={`links-${key}`}
                />
              </FormGroup>
            </FormMain>

            <FormSide>
              {/* Category */}
              <FormGroup>
                <Autocomplete
                  options={options.categories}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => (
                    <CatOptionItem {...props}>
                      <CatalogueIcon type={option.icon}/>
                      <span>{option.name}</span>
                    </CatOptionItem>
                  )}
                  disabled={loading || editing}
                  onChange={(event, newValue) => {
                    setValue('category', newValue)
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label="Category *"
                  helperText={helperText({ type: 'category', errors })}
                  error={!!errors.category}
                  _ref={e => regRef.current.category && regRef.current.category.ref(e)}
                  defaultValue={defaultValues.category}
                  key={`category-${key}`}
                />
              </FormGroup>

              {/* Subject */}
              <FormGroup>
                <Autocomplete
                  freeSolo
                  options={metaData.subjects ? metaData.subjects.map((option) => option) : []} // TODO
                  disabled={loading}
                  label="Subject"
                  margin="normal"
                  helperText="Optional subject"
                  error={!!errors.subject}
                  onChange={(event, newValue) => { setValue('subject', newValue) }}
                  defaultValue={defaultValues.subject}
                  key={`subject-${key}`}
                />
              </FormGroup>

              {/* Place */}
              <FormGroup>
                <Autocomplete
                  freeSolo
                  options={metaData.places ? metaData.places.map((option) => option) : []} // TODO
                  disabled={loading}
                  label="Place"
                  margin="normal"
                  helperText="Optional place"
                  error={!!errors.place}
                  onChange={(event, newValue) => { setValue('place', newValue) }}
                  defaultValue={defaultValues.place}
                  key={`place-${key}`}
                />
              </FormGroup>

              {/* Format */}
              <FormGroup>
                <Autocomplete
                  options={options.formats}
                  getOptionLabel={(option) => option.name || ''}
                  onChange={(event, newValue) => {
                    setValue('format', newValue)
                  }}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label="Format"
                  helperText="Optional format"
                  variant="standard"
                  defaultValue={defaultValues.format}
                  key={`format-${key}`}
                />
              </FormGroup>

              {/* Status */}
              <FormGroup>
                <Autocomplete
                  options={options.statuses}
                  getOptionLabel={(option) => option.name || ''}
                  disabled={loading}
                  onChange={(event, newValue) => {
                    setValue('status', newValue)
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label="Status"
                  helperText="Optional status"
                  variant="standard"
                  defaultValue={defaultValues.status}
                  key={`status-${key}`}
                />
              </FormGroup>

            </FormSide>
          </FormDivideWrap>

          <RichInput
            label="Notes"
            helperText="Optional general notes. Max 4000 characters"
            defaultValueMarkdown={defaultValues.notes}
            onChangeMarkdown={onNotesChange}
            disabled={loading}
            key={`notes-${key}`}
          />

          <h4>Image upload</h4>
          <FileUpload
            type="image"
            onAdd={(files) => {
              const images = getValues('images')
              setValue('images', images.concat(...files))
            }}
            onDelete={(index) => {
              const images = getValues('images')
              setValue('images', images.filter((im, idx) => idx !== index))
            }}
            onUploadDelete={(id) => {
              const images = getValues('deleteImages')
              setValue('deleteImages', images.concat(id))
            }}
            disabled={loading}
            key={`images-${key}`}
            defaultValues={defaultValues.uploadedImages}
          />

          <h4>Document upload</h4>
          <FileUpload
            type="document"
            onAdd={(files) => {
              const documents = getValues('documents')
              setValue('documents', documents.concat(...files))
            }}
            onDelete={(index) => {
              const documents = getValues('documents')
              setValue('documents', documents.filter((im, idx) => idx !== index))
            }}
            onUploadDelete={(id) => {
              const documents = getValues('deleteDocuments')
              setValue('deleteDocuments', documents.concat(id))
            }}
            disabled={loading}
            key={`documents-${key}`}
            defaultValues={defaultValues.uploadedDocuments}
          />

          <ButtonWrap className={editing && 'editing'}>
            <SimpleButton type="white" onClick={onReset} disabled={loading}>Reset</SimpleButton>
            { !saving && (
              <SimpleButton disabled={loading}>{ editing ? 'Save' : 'Add entry'}</SimpleButton>
            )}
            { saving && (
              <SimpleButton disabled>
                <CircularProgress color="inherit" size={20} />
                { editing ? 'Saving...' : 'Creating...'}
              </SimpleButton>
            )}
          </ButtonWrap>

        </Form>
      </Wrap>
    </>
  )
}

export default CatalogueForm

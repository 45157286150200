import React from "react"
import { Router } from "@reach/router"
import Edit from "../../components/catalogue/Edit"
import Add from "../../components/catalogue/Add"
import Entry from "../../components/catalogue/Entry"
import Page404 from "../404"
import {SEO} from "../../components/SEO"

const CatalogueRouter = () => {
  // Fix for hydration https://github.com/gatsbyjs/gatsby/issues/17891#issuecomment-548660698
  if (typeof window === 'undefined') return null
  return (
    <Router basepath="/catalogue">
      <Page404 default />
      <Add path="/add" />
      <Edit path="/:entryId/edit" />
      <Entry path="/:entryId" />
    </Router>
  )
}

export default CatalogueRouter

export const Head = ({ location }) => {
  if (location.pathname === '/catalogue/add') {
    return <SEO title="Add catalogue entry" pathname={location.pathname} />
  }
  if (location.pathname.match(/^\/catalogue\/[^?\/]+\/edit$/)) {
    return <SEO title="Edit catalogue entry" pathname={location.pathname} />
  }
  if (location.pathname.match(/^\/catalogue\/[^\/]+$/)) {
    return <SEO title="Catalogue entry" pathname={location.pathname} />
  }
  return <SEO />
}

import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {bodyFontFamily} from "../styles/typography"
import classNames from "classnames"

const DropArea = styled.div`
  background: var(--color-backgroundSecondary);
  display: flex;
  padding: 25px;
  border: 3px var(--color-backgroundBorder) dashed;
  cursor: pointer;
  outline: none;
  height: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
`

const DefaultWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Supported = styled.p`
  margin-bottom: 0;
`

const SimpleButton = styled.button`
  font-family: ${bodyFontFamily.join(', ')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0;
  padding: 6px 12px;
  line-height: 1.5;
  border: 1px solid rgb(209 213 219);
  color: var(--color-textSecondary);
  background: var(--color-editorPrimary);
  transition: color 0.15s ease-in-out, background-color 0.15s;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-weight: normal;
  border-radius: 3px;
  margin-left: 10px;
  &:hover {
    text-decoration: none;
    background: var(--color-editorPrimaryDark);
  }
  &.disabled {
    cursor: default;
  }
`

const Dropzone = ({
  className,
  supported,
  accept,
  handleOnDrop,
  disabled,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleOnDrop(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept,
    onDrop,
    noKeyboard: true,
    disabled,
  })

  return (
    <DropArea className={classNames(className, disabled && 'disabled')} {...getRootProps()}>
      <input {...getInputProps()} />
      {!isDragActive && (
        <DefaultWrap>
          <CloudUploadIcon style={{ fontSize: 50, color: 'var(--color-icon)'  }}  />
          <Supported>Upload {supported}</Supported>
          <p>Drag and drop some files here</p>
          <SimpleButton
            onClick={(e) => { e.preventDefault() }}
            className={classNames(disabled && 'disabled')}
          >
            Browse files
          </SimpleButton>
        </DefaultWrap>
      )}
      {!isDragReject && isDragActive && (
        <DefaultWrap>
          <p>Drop the files here ...</p>
        </DefaultWrap>
      )}
      {isDragReject && (
        <DefaultWrap>
          <Supported>Hmmm, that file type won&quot;t work.</Supported>
          <p>Try only {supported}</p>
        </DefaultWrap>
      )}
    </DropArea>
  )
}

export default Dropzone

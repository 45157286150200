import React from 'react'
import styled, {css} from "styled-components/macro"
import {bodyFontFamily} from "../../styles/typography"

const SimpleButton = styled.button`
  font-family: ${bodyFontFamily.join(', ')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0;
  padding: 6px 20px;
  line-height: 1.5;
  border: 1px solid rgb(209 213 219);
  color: var(--color-textSecondary);
  background: var(--color-editorPrimary);
  transition: color 0.15s ease-in-out, background-color 0.15s;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-weight: normal;
  border-radius: 3px;
  margin-left: 10px;
  &:hover {
    text-decoration: none;
    background: var(--color-editorPrimaryDark);
  }
  ${props => props.type === 'white' && css`
    color: var(--color-text);
    background: var(--color-white);
    &:hover {
      background: var(--color-whiteHover);
    }
  `}
  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
    span {
      margin-right: 8px;
    }
  }
`

const CatSimpleButton = (props) => (
  <SimpleButton {...props} />
)

export default CatSimpleButton

import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte'
import { stateToMarkdown } from 'draft-js-export-markdown'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import { convertToRaw } from 'draft-js'
import {bodyFontFamily} from "../styles/typography"

const Wrap = styled.div`
  margin-bottom: 10px;
  margin-top: 2px;
  position: relative;
`

const ShrinkLabel = styled.label`
  top: 36px;
  opacity: 0;
  
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: absolute;
  left: 0;
  //top: 0;
  transform: translate(0, 20px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  
  ${(props) => (props.focused || props.hasText) && css`
    opacity: 1;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    top: 41px;
  `}
  ${(props) => (props.focused) && css`
    color: #3f51b5;
  `}
`

const RichFormWrap = styled.div`
  position: relative;
  ${(props) => !props.focused && !props.hasText && css`
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    ${!props.disabled && css`
      &:hover {
        &:after {
          border-bottom: 2px solid rgba(0, 0, 0, 0.87);
          left: 0;
          right: 0;
          bottom: -1px;
          content: "";
          position: absolute;
          pointer-events: none;
        }
      }
    `}
  `} 
  #mui-rte-editor {
    position: relative;
    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #3f51b5;
      pointer-events: none;
    }
  }
  ${(props) => (props.focused || props.hasText) && css`
      #mui-rte-editor {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      }
  `}
   ${(props) => (!props.focused && !props.hasText) && css`
    #mui-rte-editor {
      &:after {
        transition: none;
      }
    }
  `}
  ${(props) => (props.focused) && !props.disabled && css`
    #mui-rte-editor {
      border-bottom: none;
      &:after {
        transform: scaleX(1);
      }
    }
  `}
  ${(props) => (props.label) && css`
    #mui-rte-toolbar {
      transition: margin-bottom 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  `}
  ${(props) => (props.label) && (props.focused || props.hasText) && !props.disabled && css`
    #mui-rte-toolbar {
      margin-bottom: 22px;
    }
  `}
  ${(props) => props.disabled && css`
      border-bottom: 1px dotted rgba(0, 0, 0, 0.6);
      opacity: 0.7;
      .public-DraftEditor-content {
        cursor: default;
        user-select: none;
      }
      #mui-rte-container > div {
        cursor: default!important;
      }
      #mui-rte-editor {
        border-bottom: none;
      }
  `}
  
  .public-DraftEditor-content {
    h2 {
      font-family: ${bodyFontFamily.join(',')};
      font-size: 18px;
      margin-bottom: 8px;
    }
  }

`

const HelperText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  ${props => props.disabled && css`
    opacity: 0.7;
  `} 
`

const theme = createTheme({
  // Set up your custom MUI theme here
})

const MUIRichTextEditorMemo = React.memo(({
  label,
  setIsFocused,
  setHasText,
  defaultValue,
  onChangeMarkdown,
  disabled,
}) => {
  return (
    <MUIRichTextEditor
      label={label}
      // onSave={(d) => console.log(d)}
      controls={[
        'title',
        'bold',
        'italic',
        // 'underline',
        // 'strikethrough',
        // 'highlight',
        'undo',
        'redo',
        'link',
        // 'media',
        'numberList',
        'bulletList',
        'quote',
        'code',
        'clear',
        // 'save'
      ]}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onChange={(editorState) => {
        const contentState = editorState.getCurrentContent()
        setHasText(contentState.hasText())
        onChangeMarkdown(stateToMarkdown(contentState))
      }}
      defaultValue={defaultValue}
      readOnly={disabled}
    />
  )
})

const RichInput = ({
  label,
  helperText,
  onChangeMarkdown = () => {},
  defaultValueMarkdown = '',
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [hasText, setHasText] = useState(false)

  const defaultValue = useMemo(() => {
    return JSON.stringify(convertToRaw(stateFromMarkdown(defaultValueMarkdown)))
  }, [defaultValueMarkdown])

  return (
    <ThemeProvider theme={theme}>
      <Wrap>
        { label && !disabled && (
          <ShrinkLabel
            focused={isFocused}
            hasText={hasText}
          >
            {label}
          </ShrinkLabel>
        )}
        <RichFormWrap
          focused={isFocused}
          hasText={hasText}
          label={label}
          disabled={disabled}
        >
          <MUIRichTextEditorMemo
            label={label}
            setIsFocused={setIsFocused}
            setHasText={setHasText}
            defaultValue={defaultValue}
            onChangeMarkdown={onChangeMarkdown}
            disabled={disabled}
          />
        </RichFormWrap>
        {helperText && (
          <HelperText disabled={disabled}>{helperText}</HelperText>
        )}
      </Wrap>
    </ThemeProvider>
  )
}

export default  React.memo(RichInput)

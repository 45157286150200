import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const AlertMessage = ({
  title,
  message,
  severity,
  onClose,
  ...rest
}) => {
  return (
      <Alert
        onClose={onClose}
        severity={severity}
        {...rest}
      >
        { title && (
          <AlertTitle>{title}</AlertTitle>
        )}
        {message}
      </Alert>
  )
}

AlertMessage.propType = {
  title: PropTypes.string,
  message: PropTypes.string,
  severity: PropTypes.oneOf(['alert', 'warning', 'info', 'success']),
  onClose: PropTypes.func,
}

export default AlertMessage

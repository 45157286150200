import React, {useEffect, useState} from "react"
import styled from 'styled-components/macro'
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import MUICheckbox from "@mui/material/Checkbox"
import FormHelperText from "@mui/material/FormHelperText"

const StyledFormLabel = styled(FormLabel)`
  font-size: 12px!important;
  line-height: 11px!important;
`

const StyledFormHelperText = styled(FormHelperText)`
  margin-top: -2px!important;
`

const Checkbox = React.memo(({
  label,
  defaultValue = false,
  onChange = () => {},
  helperText,
  disabled,
 }) => {
  const [checked, setChecked] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue) setChecked(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    onChange(checked)
  }, [checked])

  return (
    <FormControl component="fieldset" variant="standard">
      {label && <StyledFormLabel component="legend">{label}</StyledFormLabel>}
      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={
            <MUICheckbox checked={checked} onChange={() => setChecked(!checked)} />
          }
          label="Approximate year"
        />
      </FormGroup>
      {helperText && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </FormControl>
  )
})

export default Checkbox

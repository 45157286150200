import React, {useCallback, useEffect, useMemo, useState} from "react"
import Autocomplete from '@mui/material/Autocomplete'
import _ from "lodash"
import TextField from "@mui/material/TextField/TextField"
import styled from "styled-components/macro"

const LinkInput = styled(Autocomplete)`
  .MuiChip-root {
    width: 100%;
    justify-content: space-between;
  } 
`

const TaggedAutoComplete = React.memo(({
   label,
   placeholder,
   helperText,
   fullWidth = false,
   options,
   defaultValue,
   onChange = () => {},
   error,
   _ref,
   disabled,
 }) => {
  const Component = useMemo(() => fullWidth ? LinkInput : Autocomplete, [fullWidth])

  const [value, setValue] = useState(defaultValue || [])
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const _onChange = useCallback((event, newValue) => {
    setValue(newValue)
  }, [setValue, onChange])

  const onInputChange = useCallback((event, newInputValue) => {
    const splitOptions = newInputValue ? newInputValue.split(',') : []
    if (splitOptions.length <= 1) {
      setInputValue(newInputValue)
      onChange([...value, newInputValue])
      return
    }
    setValue(currentValue => (
      _.uniq(currentValue)
        .concat(splitOptions)
        .map((x) => x.trim())
        .filter((x) => x)
    ))
    setInputValue('')
  }, [value, setValue, setInputValue])

  const renderInput = useCallback((params) => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      variant="standard"
      error={error}
      ref={_ref}
    />
  ), [label, placeholder, helperText])

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <Component
      multiple
      freeSolo
      options={options}
      value={value}
      inputValue={inputValue}
      onChange={_onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      disabled={disabled}
    />
  )
})

export default TaggedAutoComplete

import React, {useEffect, useContext, useState} from 'react'
import styled from 'styled-components/macro'
import CatalogueIcon from '../../components/CatalogueIcon'
import Layout from '../../components/Layout'
import {CatalogueContext} from "../../catalogue/CatalogueContext"
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {bodyFontFamily} from "../../styles/typography"
import IconButton from "@mui/material/IconButton"
import {UserCircleIcon, HashtagIcon} from '@heroicons/react/24/solid'
import ReactMarkdown from 'react-markdown'
import media from "../../styles/media"
import Button from "@mui/material/Button"
import {isLoggedIn} from "../../services/auth"
import { Link, navigate } from "gatsby"
import ImageKit from "imagekit-javascript"
import { Gallery, Item } from 'react-photoswipe-gallery'
import DocIcon from "../../images/icons/doc.inline.svg"
import PdfIcon from "../../images/icons/pdf.inline.svg"
import TxtIcon from "../../images/icons/txt.inline.svg"
import XlsIcon from "../../images/icons/xls.inline.svg"
import EditError from "../../components/catalogueForm/EditError"
import {SEO} from "../../components/SEO"

import 'photoswipe/dist/photoswipe.css'

const Wrap = styled.div`
  background: var(--color-backgroundSecondary);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
`

const Header = styled.div`
  background: var(--color-secondary);
  color: var(--color-white);
  border-bottom: 1px solid #D8D8D8;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const HeaderText = styled.div`
  margin-right: auto;
`

const Title = styled.h2`
  //color: var(--color-text);
  color: var(--color-white);
  margin: 0;
  font-family: ${bodyFontFamily.join(', ')};
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
`

const CatalogueNo = styled.h3`
  //color: var(--color-text);
  color: var(--color-white);
  margin: 0;
  font-family: ${bodyFontFamily.join(', ')};
  font-weight: 300;
  font-size: 24px;
`

const AdminControls = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

const AdminButton = styled(Button)`
    border: 1px solid rgba(255, 255, 255, 0.5)!important;
    color: #fff!important;
    font-size: 12px!important;
    padding: 1px 9px!important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.04)!important;
      border: 1px solid #fff!important;
    }
`

const LinkForButton = styled(Link)`
  &:hover{
    text-decoration: none;
  }
`

const Content = styled.div`
  padding: 20px 25px 12px;
  display: flex;
  @media ${media.ltTablet} {
    flex-direction: column;
  }
`

const Main = styled.section`
  flex: 1;
  padding-right: 18px;
  @media ${media.ltTablet} {
    padding-right: 0;
  }
`

const DetailList = styled.dl`
  display: grid;
  grid-template-columns: 100px auto;
  margin: 0;
  padding: 0;
  dt {
    grid-column: 1;
  }
  
  dd {
    grid-column: 2;
    margin-bottom: 1.5rem;
  }
  
  dd.clip-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &.loading dd {
    display: flex;
    align-items: center;
    height: 24px;
  }
  
  dd.markdown {
    h2 {
      font-family: ${bodyFontFamily.join(',')};
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
`

const SideDetailList = styled.dl`
  margin: 0;
  padding: 0;
  
  dd.markdown {
    h2 {
      font-family: ${bodyFontFamily.join(',')};
      font-size: 18px;
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 0;
    }
    blockquote {
      font-style: italic;
      border-left: 4px solid #f5f5f5;
      padding: 0 12px;
      margin: 10px 0;
    }
  }
`

const None = styled.span`
  opacity: 0.25;
  //font-style: italic;
`

const Side = styled.section`
  flex: 1;
  border-left: 1px solid #D8D8D8;
  padding-left: 18px;
  @media ${media.ltTablet} {
    padding-left: 0;
    border-left: none;
  }
`

const CloseButton = styled(IconButton)`
  align-self: flex-start;
  color: #fff!important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.04)!important;
  }
`

const Links = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
  }
  a {
    display: block;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const IconList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  li {
    display: flex;
    background: #ECECEC;
    border-radius: 25px;
    align-items: center;
    padding: 3px 10px 3px 8px;
    margin: 0;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    flex-shrink: 0;
  }
`

const LoadTitle = styled.div`
  width: 250px;
  max-width: 50%;
  height: 16px;
  border-radius: 10px;
`

const LoadCatNo = styled.div`
  width: 100px;
  height: 26px;
  border-radius: 10px;
`

const LoadIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 10px;
`

const LoadInfo = styled.div`
  width: 150px;
  height: 16px;
  border-radius: 10px;
`

const Images = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
    }
  }
`

const Documents = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  li {
    margin: 0;
    padding: 0;
  }
  li a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    font-size: 12px;
    text-align: center;
    gap: 10px;
    color: var(--color-text);
    &:hover {
      text-decoration: none;
    }
  }
`

const imagekit = new ImageKit({
  urlEndpoint: "https://ik.imagekit.io/harburyheritage"
})

const fileIcons = {
  '.doc': <DocIcon />,
  '.docx': <DocIcon />,
  '.pdf': <PdfIcon />,
  '.txt': <TxtIcon />,
  '.xls': <XlsIcon />,
  '.xlsx': <XlsIcon />,
}

const isEmpty = ((s) => {
  if (!s) return true
  if (typeof s !== 'string') return true
  return s.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '').trim() === ''
})

const Entry = ({ entryId, location }) => {
  const [entry, setEntry] = useState()
  const { error, loading, entries } = useContext(CatalogueContext)

  useEffect(() => {
    if (!loading && entries) {
      setEntry(entries.find(e => e.catalogueNo === entryId) || false)
    }
  }, [loading, entries])

  if (error || entry === false) {
    const type = error ? 'error' : 'not-found'
    return (
      <Layout>
        <EditError type={type} />
      </Layout>
    )
  }

  if (loading || (!entry && entry !== false)) {
    return (
      <Layout>
        <Wrap>
          <Header>
            <LoadIcon className="skeleton-box" />
            <HeaderText>
              <Title><LoadTitle className="skeleton-box" /></Title>
              <CatalogueNo><LoadCatNo className="skeleton-box" /></CatalogueNo>
            </HeaderText>
            <Link to="/catalogue" onClick={(e) => {if (location?.state?.prevPath) { e.preventDefault(); navigate(-1) }}}>
              <CloseButton aria-label="close">
                <CloseIcon />
              </CloseButton>
            </Link>
          </Header>
          <Content>
            <Main>
              <DetailList className="loading">
                <dt>Category</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Format</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Year</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Source</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>People</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Subject</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Place</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Status</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Tags</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Links</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
                <dt>Spares</dt>
                <dd><LoadInfo className="skeleton-box" /></dd>
              </DetailList>
            </Main>
            <Side>
              <dt>Notes</dt>
              <dd><LoadInfo className="skeleton-box" /></dd>
              <dt>Images</dt>
              <dd><LoadInfo className="skeleton-box" /></dd>
              <dt>Documents</dt>
              <dd><LoadInfo className="skeleton-box" /></dd>
            </Side>
          </Content>
        </Wrap>
      </Layout>
    )
  }

  if (entry) {
    const loggedIn = isLoggedIn()
    return (
      <Layout>
        <Wrap>
          <Header>
            <CatalogueIcon type={entry.category.icon} color="var(--color-white)" size={48} />
            <HeaderText>
              <Title>{entry.title}</Title>
              <CatalogueNo>{entry.catalogueNo}</CatalogueNo>
            </HeaderText>
            {loggedIn && (
              <AdminControls>
                <LinkForButton to="edit">
                  <AdminButton variant="outlined" startIcon={<EditIcon fontSize="inherit" />} size="small">
                    Edit
                  </AdminButton>
                </LinkForButton>
              </AdminControls>
            )}
            <Link to="/catalogue" onClick={(e) => {if (location?.state?.prevPath) { e.preventDefault(); navigate(-1) }}}>
              <CloseButton aria-label="close">
                <CloseIcon />
              </CloseButton>
            </Link>
          </Header>
          <Content>
            <Main>
              <DetailList>
                <dt>Category</dt>
                <dd>{entry.category?.name || <None>None</None>}</dd>

                <dt>Format</dt>
                <dd>{entry.format?.name || <None>None</None>}</dd>

                <dt>Year</dt>
                <dd>{(entry.year && entry.yearCirca ? `c.${entry.year}` : entry.year) || <None>None</None>}</dd>

                <dt>Source</dt>
                <dd>{entry.source || <None>None</None>}</dd>

                <dt>People</dt>
                <dd>
                  {!entry.people && <None>None</None>}
                  {entry.people && (
                    <IconList>
                      {entry.people.split('\n').map((person, i) => (
                        <li key={`${person}-${i}`}>
                          <UserCircleIcon />
                          {person}
                        </li>
                      ))}
                    </IconList>
                  )}
                </dd>

                <dt>Subject</dt>
                <dd>{entry.subject || <None>None</None>}</dd>

                <dt>Place</dt>
                <dd>{entry.place || <None>None</None>}</dd>

                <dt>Status</dt>
                <dd>{entry.status?.name || <None>None</None>}</dd>

                <dt>Tags</dt>
                <dd>
                  {!entry.tags && <None>None</None>}
                  {entry.tags && (
                    <IconList>
                      {entry.tags.split('\n').map((tag, i) => (
                        <li key={`${tag}-${i}`}>
                          <HashtagIcon />
                          {tag}
                        </li>
                      ))}
                    </IconList>
                  )}
                </dd>

                <dt>Links</dt>
                <dd className="clip-text">
                  {!entry.links && <None>None</None>}
                  {entry.links && (
                    <Links>
                      {entry.links.split('\n').map((link, i) => (
                        <li key={`${link}-${i}`}>
                          <a href={link} target="_blank">{link}</a>
                        </li>
                      ))}
                    </Links>
                  )}
                </dd>

                <dt>Spares</dt>
                <dd>{entry.spares || <None>None</None>}</dd>
              </DetailList>
            </Main>
            <Side>
              <SideDetailList>
                <dt>Notes</dt>
                <dd className="markdown">
                  {isEmpty(entry.notes) && <None>None</None>}
                  {!isEmpty(entry.notes) && <ReactMarkdown>{entry.notes}</ReactMarkdown>}
                </dd>

                <dt>Images</dt>
                <dd>
                  {(!entry.images || entry.images.length === 0) && <None>None</None>}
                  {entry.images && (
                    <Gallery>
                      <Images>
                        {entry.images.map(({ name, url, width, height }, i) => {

                          const imageUrl = imagekit.url({
                            path: url,
                            urlEndpoint: process.env.GATSBY_IMAGEKIT_URL,
                            transformation: [{ height: 1000 }],
                          })

                          const thumbnailUrl = imagekit.url({
                            path: url,
                            urlEndpoint: process.env.GATSBY_IMAGEKIT_URL,
                            transformation: [{ height: 200 }],
                          })

                          return (
                            <li key={`${imageUrl}-${i}`}>
                              <Item
                                original={imageUrl}
                                thumbnail={thumbnailUrl}
                                width={width}
                                height={height}
                              >
                                {({ ref, open }) => (
                                  <img ref={ref} onClick={open} src={thumbnailUrl} alt={name} />
                                )}
                              </Item>
                            </li>
                          )
                        })}
                      </Images>
                    </Gallery>
                  )}
                </dd>

                <dt>Documents</dt>
                <dd>
                  {(!entry.documents || entry.documents.length === 0) && <None>None</None>}
                  {entry.documents && (
                    <Documents>
                      {entry.documents.map(({ name, url, ext }, i) => {
                        const docUrl = imagekit.url({
                          path: url,
                          urlEndpoint: process.env.GATSBY_IMAGEKIT_URL,
                          queryParameters: { 'ik-attachment': true },
                        })
                        return (
                          <li key={`${url}-${i}`}>
                            <a href={docUrl} target="_blank" download>
                              {name}
                              {fileIcons[ext]}
                            </a>
                          </li>
                        )
                      })}
                    </Documents>
                  )}
                </dd>
              </SideDetailList>
            </Side>
          </Content>
        </Wrap>
      </Layout>
    )
  }
}

export default Entry

export const Head = ({ location }) => (
  <SEO title="Catalogue" pathname={location.pathname} />
)

import React, {useCallback, useContext, useState} from 'react'
import usePortal from 'react-useportal'
import CatalogueForm from "../catalogueForm/CatalogueForm"
import {CatalogueContext} from "../../catalogue/CatalogueContext"
import CreatedModal from "../catalogueForm/CreatedModal"
import {SEO} from "../SEO"
import Layout from "../Layout"
import {isLoggedIn} from "../../services/auth"
import Page404 from "../../pages/404"

const Add = () => {
  const { entries, meta, version, loading, error } = useContext(CatalogueContext)
  const [openPortal, closePortal, isOpen, Portal] = usePortal()
  const [entryIdPortal, setPortalEntryId] = useState()
  const [resetKey, setResetKey] = useState()

  const onCreated = useCallback(({ e, entryId }) => {
    openPortal({ currentTarget: { contains: () => false } })
    setPortalEntryId(entryId)
  }, [])

  const onClose = useCallback(({ scrollTop = false, reset = false }) => {
    closePortal({ currentTarget: { contains: () => false } })
    if (reset) setResetKey(resetKey ? resetKey + 1 : 1)
    if (scrollTop) window.scrollTo(0, 0)
  }, [resetKey])

  if (!isLoggedIn()) return <Page404 />

  return (
    <Layout>
      <CatalogueForm
        entries={entries}
        meta={meta}
        version={version}
        entryLoading={loading}
        entryError={error}
        onCreated={onCreated}
        key={resetKey}
      />
      {(isOpen) && (
        <Portal>
          <CreatedModal
            onClose={onClose}
            entryId={entryIdPortal}
            entries={entries}
          />
        </Portal>
      )}
    </Layout>
  )
}

export default Add

export const Head = ({ location }) => (
  <SEO title="Catalogue add" pathname={location.pathname} />
)

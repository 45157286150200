import React, {useEffect, useState, useCallback} from "react"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

export default React.memo(({
  freeSolo,
  options,
  getOptionLabel,
  renderOption,
  disabled,
  onChange,
  isOptionEqualToValue,
  groupBy,
  label,
  helperText,
  error,
  _ref,
  defaultValue = null,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (!defaultValue) return
    if (freeSolo) {
      setValue(defaultValue)
      return
    }
    setValue(options.find(o => o.id === defaultValue.id))
  }, [defaultValue])

  const _onChange = useCallback((e, newValue) => {
    setValue(newValue)
    onChange(e, newValue)
  }, [])

  const _onInputChange = useCallback((e, newValue) => {
    if (freeSolo) {
      setValue(newValue)
      onChange(e, newValue)
    }
  }, [freeSolo])

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      value={value}
      disabled={disabled}
      onChange={_onChange}
      onInputChange={_onInputChange}
      isOptionEqualToValue={isOptionEqualToValue}
      groupBy={groupBy}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          error={error}
          variant="standard"
          ref={_ref}
        />
      )}
    />
  )
})

import React, {useCallback, useEffect, useState} from "react"
import TextField from '@mui/material/TextField'

const CatTextField = ({
  id,
  label,
  type,
  defaultValue,
  helperText,
  error,
  style,
  disabled,
  onChange,
  _ref,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const _onChange = useCallback((e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }, [setValue, onChange])

  return (
    <TextField
      id={id}
      label={label}
      type={type}
      helperText={helperText}
      variant="standard"
      error={error}
      style={style}
      disabled={disabled}
      value={value}
      onChange={_onChange}
      ref={_ref}
    />
  )
}

export default CatTextField

import React from 'react'
import styled from 'styled-components/macro'
import {FaceFrownIcon} from "@heroicons/react/24/outline"
import media from "../../styles/media"

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 25px;
  @media ${media.ltTablet} {
    padding: 15px 15px;
  }
`

const ContentWrap = styled.div`
  display: flex;
  background: var(--color-backgroundSecondary);
  max-width: 900px;
  flex: 1;
  padding: 40px 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  @media ${media.ltTablet} {
    padding: 15px 15px;
  }
`

const IconWrap = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${media.ltTablet} {
    display: none;
  }
  svg {
    color: var(--color-icon);
    max-height: 60px;
  }
  svg g {
    stroke: var(--color-icon)!important;
  }
`

const InfoWrap = styled.div`
  flex: 8;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    text-decoration: underline;
    font-weight: 500;
  }
`

const types = {
  error: {
    header: 'An unknown error occurred',
  },
  'not-found': {
    header: 'No entry found',
  }
}

const EditError = ({ type }) => (
    <Wrap>
      <ContentWrap>
        <IconWrap>
          {type === 'error' && <FaceFrownIcon />}
          {type === 'not-found' && <FaceFrownIcon />}
        </IconWrap>
        <InfoWrap>
          <h2>{types[type].header}</h2>
          {type === 'error' && (
            <ul>
              <li>Sorry an occurred whilst loading entry details.</li>
              <li>Please check your your network connection and refresh the page.</li>
              <li>If you still getting this issue please contact your admin.</li>
            </ul>
          )}
          {type === 'not-found' && (
            <ul>
              <li>Sorry we couldn&apos;t find an entry with that catalogue number.</li>
              <li>Check the catalogue number in the address bar and try refreshing the page.</li>
              <li>If you still getting this issue please contact your admin.</li>
            </ul>
          )}
        </InfoWrap>
      </ContentWrap>
    </Wrap>
)

export default EditError

import React, {useEffect, useState} from "react"
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import TextField from "@mui/material/TextField/TextField"

const YearPicker = React.memo(({
   defaultValue,
   onChange,
   helperText = 'Optional year supplied if known',
   disabled,
   minYear,
   maxYear,
 }) => {
  const [year, setYear] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue) setYear(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    onChange(year)
  }, [year])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year']}
        label="Year"
        minDate={minYear}
        maxDate={maxYear}
        value={year}
        disabled={disabled}
        onChange={(value) => {
          setYear(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            variant="standard"
          />
        )}
      />
    </LocalizationProvider>
  )
})

export default YearPicker

import React, {useEffect, useContext} from 'react'
import CatalogueForm from "../catalogueForm/CatalogueForm"
import {CatalogueContext} from "../../catalogue/CatalogueContext"
import {SEO} from "../SEO"
import Layout from "../Layout"
import {isLoggedIn} from "../../services/auth"
import Page404 from "../../pages/404"


const Edit = ({ entryId }) => {
  const [entry, setEntry] = React.useState()
  const { error, loading, entries, meta, version } = useContext(CatalogueContext)

  useEffect(() => {
    if (entries && !entry) setEntry(entries.find(e => e.catalogueNo === entryId))
  }, [entry, entries])

  if (!isLoggedIn()) return <Page404 />

  return (
    <Layout>
      <CatalogueForm
        entry={entry}
        entryLoading={loading}
        entryError={error}
        entries={entries}
        meta={meta}
        version={version}
        editing
      />
    </Layout>
  )
}

export default Edit

export const Head = ({ location }) => (
  <SEO title="Catalogue edit" pathname={location.pathname} />
)

import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import {DocumentIcon, MagnifyingGlassIcon, PlusIcon, DocumentDuplicateIcon} from "@heroicons/react/24/outline"
import media from "../../styles/media"
import useScrollBlock from "../../services/useScrollBlock"
import CloseIcon from '@mui/icons-material/Close'
import MUIIconButton from '@mui/material/IconButton'
import {Link} from "gatsby"
import {bodyFontFamily} from '../../styles/typography'
import CircularProgress from "@mui/material/CircularProgress"
import classnames from "classnames"

const Background = styled.div`
  background: rgba(0,0,0,0.3);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Wrap = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 25px;
  width: 100%;
  min-height: 100vh; 
  @media ${media.ltTablet} {
    padding: 15px 15px;
  }
`

const ContentWrap = styled.div`
  display: flex;
  background: var(--color-backgroundSecondary);
  max-width: 700px;
  box-sizing: border-box;
  flex: 1;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  @media ${media.ltTablet} {
    padding: 15px 15px;
    max-width: 400px;
  }
`

const InfoWrap = styled.div`
  flex: 8;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4,1fr);
    @media ${media.ltTablet} {
      grid-template-columns: repeat(2,2fr);
    }
  }
  li {
    aspect-ratio: 1;
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 24px;
    font-family: ${bodyFontFamily.join(', ')};
    font-weight: 400;
  }
`

const IconButton = styled(Link)`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #f5f5f5;
  cursor: pointer;
  padding: 10px;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: background-color 200ms ease-in-out;
  &:hover {
    text-decoration: none;
    background: #e7e7e7;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
    background: #f5f5f5!important;
    opacity: 0.5;
  }
  svg {
    color: var(--color-icon);
    max-height: 48px;
  }
  svg g {
    stroke: var(--color-icon)!important;
  }
  .text {
    margin-top: 10px;
  }
  &.disabled .text {
    margin-top: 15px;
  }
  .MuiCircularProgress-root {
    margin-top: 0;
  }
`

const CloseButton = styled(MUIIconButton)`
  align-self: flex-start;
  &:hover {
    background-color: rgba(255, 255, 255, 0.04)!important;
  }
`

const CreatedModal = ({
  entryId = 'unknown',
  onClose = () => {},
  entries = [],
}) => {
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    blockScroll()
    return () => { allowScroll() }
  }, [])

  const entryLoaded = useMemo(() => {
    if (!entries || !entryId) return false
    return !!entries.find(e => e.catalogueNo === entryId)
  }, [entries, entryId])

  return (
    <>
      <Background />
      <Wrap>
        <ContentWrap>
          <InfoWrap>
            <Header>
              <h2>Catalogue entry {entryId} created</h2>
              <CloseButton aria-label="close" onClick={() => { onClose({ scrollTop: true, reset: true }) }}>
                <CloseIcon />
              </CloseButton>
            </Header>
            <p>What would you like to do next?</p>
            <ul>
              <li>
                <IconButton to={`/catalogue/${entryId}`} className={classnames(!entryLoaded && 'disabled')}>
                  { entryLoaded && <DocumentIcon /> }
                  { !entryLoaded && <CircularProgress /> }
                  <span className="text">Go to entry</span>
                </IconButton>
              </li>
              <li>
                <IconButton>
                  <DocumentDuplicateIcon to="#" onClick={() => { onClose({ scrollTop: true, reset: false }) }} />
                  <span className="text">Duplicate entry</span>
                </IconButton>
              </li>
              <li>
                <IconButton to="/catalogue/add" onClick={() => { onClose({ scrollTop: true, reset: true }) }}>
                  <PlusIcon />
                  <span className="text">New entry</span>
                </IconButton>
              </li>
              <li>
                <IconButton to="/catalogue">
                  <MagnifyingGlassIcon />
                  <span className="text">Return to catalogue</span>
                </IconButton>
              </li>
            </ul>
          </InfoWrap>
        </ContentWrap>
      </Wrap>
    </>
  )
}

export default CreatedModal

import React, {useState, useMemo, useCallback, useEffect, useRef} from 'react'
import styled, { css } from 'styled-components/macro'
import ImageKit from 'imagekit-javascript'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Dropzone from './Dropzone'
import media from '../styles/media'
import DocIcon from '../images/icons/doc.inline.svg'
import PdfIcon from '../images/icons/pdf.inline.svg'
import TxtIcon from '../images/icons/txt.inline.svg'
import XlsIcon from '../images/icons/xls.inline.svg'

const Wrap = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  @media screen and ${media.ltTablet} {
    flex-direction: column;
  }
`

const DropWrap = styled.div`
  flex: 3;
`

const FilesWrap = styled.div`
  flex: 2;
  display: flex;
  border: 2px var(--color-backgroundBorder) solid;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  ${(props) => (props.disabled) && css`
    opacity: 0.7;
  `}
`

const None = styled.p`
  opacity: 0.6;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
`

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: var(--color-background);
`

const Thumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-height: 70px;
  max-width: 100px;
  svg {
    width: auto;
    height: 100%;
    max-height: 70px;
    max-width: 100px;
  }
  img {
    margin: 0;
    max-height: 70px;
    max-width: 100px;
    line-height: 0;
  }
`

const Name = styled.p`
  font-size: 14px;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`

// const accept = [
//   'image/heic',
//   'image/heif',
//   'image/jpeg',
//   'image/png',
//   'image/jpg',
//   'application/vnd.ms-excel',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'application/docx',
//   'application/pdf',
//   'text/plain',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
// ]

const types = {
  image: {
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    supported: 'Images (JPG, PNG, HEIC)',
    none: 'No images added',
  },
  document: {
    accept: {
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/docx': [],
      'application/pdf': [],
      'text/plain': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    supported: 'Documents (TXT, PDF, Word, Excel)',
    none: 'No documents added',
  },
}

const typeLookup = {
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'application/docx': 'doc',
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
}

const icons = {
  'doc': <DocIcon/>,
  'pdf': <PdfIcon/>,
  'txt': <TxtIcon/>,
  'xls': <XlsIcon/>,
}

// TODO Env?
const imageKitUrl = 'https://ik.imagekit.io/harburyheritage'

const imagekit = new ImageKit({
  urlEndpoint: imageKitUrl,
})

const FileUpload = ({
  type = 'image',
  onAdd = () => {},
  onDelete = () => {},
  disabled,
  defaultValues = [],
  onUploadDelete = () => {},
}) => {
  const [files, setFiles] = useState([])
  const [deletedFiles, setDeletedFiles] = useState([])

  const addFiles = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      ...(file.type.startsWith('image/') && { img: URL.createObjectURL(file) }),
      type: file.type.startsWith('image/') ? 'image' : typeLookup[file.type],
      mime: file.type,
    }))

    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    onAdd(acceptedFiles)
  }, [])

  const handleDelete = useCallback((idx) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => i !== idx))
    onDelete(idx)
  }, [])

  const {
    supported,
    accept,
    none,
  } = useMemo(() => types[type], [type])

  const uploadedFiles = useMemo(() => {
    return defaultValues.filter((f) => !deletedFiles.includes(f.id))
  }, [defaultValues, deletedFiles])

  // Clear files on default values update (aka on save)
  const defaultValuesRef = useRef(defaultValues)
  useEffect(() => {
    if (JSON.stringify(defaultValues) !== JSON.stringify(defaultValuesRef.current)) {
      defaultValuesRef.current = defaultValues
      setFiles([])
    }
  }, [defaultValues])

  return (
      <Wrap>
        <DropWrap>
          <Dropzone
            supported={supported}
            accept={accept}
            handleOnDrop={addFiles}
            disabled={disabled}
          />
        </DropWrap>
        <FilesWrap disabled={disabled}>
          { files.length === 0 && uploadedFiles.length === 0 && (
            <None>{none}</None>
          )}
          {uploadedFiles.map(({ id, name, url, width, height, ext }, i) => {
            const thumbnailUrl = type === 'image' && imagekit.url({
              path: url.replace(imageKitUrl, ''),
              urlEndpoint: process.env.GATSBY_IMAGEKIT_URL,
              transformation: [{ height: 200 }],
            })
            return (
              <ItemWrap key={i}>
                <Thumb>
                  { thumbnailUrl && <img src={thumbnailUrl} alt={name} /> }
                  { ext && <>{icons[ext.replace('.', '')]}</> }
                </Thumb>
                <Name>{name}</Name>
                { !disabled && (
                  <IconButton onClick={() => {
                    onUploadDelete(id)
                    setDeletedFiles(deletedFiles.concat(id))
                  }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ItemWrap>
            )
          })}
          { files.map((f, i) => (
            <ItemWrap key={i}>
              <Thumb>
                { f.type === 'image' && <img src={f.img} alt="img" /> }
                { f.type !== 'image' && <>{icons[typeLookup[f.mime]]}</> }
              </Thumb>
              <Name>{f.file.name}</Name>
              { !disabled && (
                <IconButton onClick={() => handleDelete(i)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ItemWrap>
          ))}
        </FilesWrap>
      </Wrap>
  )
}

export default FileUpload
